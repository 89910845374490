import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import { Wand2, List, Replace, Info } from 'lucide-react';

interface ToolsProps {
  result: any;
  testString: string;
  pattern: string;
  flags: string;
}

export function Tools({ result, testString, pattern, flags }: ToolsProps) {
  const [replacement, setReplacement] = useState('');

  const getReplaceResult = () => {
    try {
      const regex = new RegExp(pattern, flags);
      return testString.replace(regex, replacement);
    } catch (error) {
      return 'Invalid regular expression';
    }
  };

  const getExplanation = () => {
    const parts = [];
    if (pattern.includes('^')) parts.push('Matches start of line');
    if (pattern.includes('$')) parts.push('Matches end of line');
    if (pattern.includes('\\w')) parts.push('Matches word characters');
    if (pattern.includes('\\d')) parts.push('Matches digits');
    return parts.join('. ') || 'Simple text matching';
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
      <Tab.Group>
        <Tab.List className="flex space-x-1 border-b border-gray-200 dark:border-gray-700 px-2">
          <Tab className={({ selected }) =>
            `px-4 py-2 text-sm font-medium flex items-center space-x-2
             ${selected ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`
          }>
            <Replace className="w-4 h-4" />
            <span>Replace</span>
          </Tab>
          <Tab className={({ selected }) =>
            `px-4 py-2 text-sm font-medium flex items-center space-x-2
             ${selected ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`
          }>
            <List className="w-4 h-4" />
            <span>List</span>
          </Tab>
          <Tab className={({ selected }) =>
            `px-4 py-2 text-sm font-medium flex items-center space-x-2
             ${selected ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`
          }>
            <Wand2 className="w-4 h-4" />
            <span>Details</span>
          </Tab>
          <Tab className={({ selected }) =>
            `px-4 py-2 text-sm font-medium flex items-center space-x-2
             ${selected ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`
          }>
            <Info className="w-4 h-4" />
            <span>Explain</span>
          </Tab>
        </Tab.List>
        <Tab.Panels className="p-4">
          <Tab.Panel>
            <div className="space-y-4">
              <input
                type="text"
                value={replacement}
                onChange={(e) => setReplacement(e.target.value)}
                placeholder="Replacement text..."
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600"
              />
              <div className="bg-gray-50 dark:bg-gray-900 p-4 rounded">
                {getReplaceResult()}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="space-y-2">
              {result?.matches?.map((match: any, index: number) => (
                <div key={index} className="bg-gray-50 dark:bg-gray-900 p-2 rounded">
                  {match.match}
                </div>
              ))}
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="space-y-2">
              {result?.matches?.map((match: any, index: number) => (
                <div key={index} className="bg-gray-50 dark:bg-gray-900 p-2 rounded">
                  <div>Match: {match.match}</div>
                  <div>Index: {match.index}</div>
                  {match.groups?.length > 0 && (
                    <div>Groups: {match.groups.join(', ')}</div>
                  )}
                </div>
              ))}
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="bg-gray-50 dark:bg-gray-900 p-4 rounded">
              {getExplanation()}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
