import { useState, useEffect } from 'react';
import { config } from '../config';

interface RegexResult {
  matches: Array<{
    match: string;
    groups: string[];
    index: number;
  }>;
  count: number;
}

export function useRegexTester() {
  const [pattern, setPattern] = useState('([A-Z])\\w+');
  const [flags, setFlags] = useState('g');
  const [testString, setTestString] = useState('Hello World! Testing RegExp');
  const [result, setResult] = useState<RegexResult | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const testRegex = async () => {
    if (!pattern || !testString) return;

    try {
      setIsLoading(true);
      setError(null);

      // Validate regex syntax locally first
      new RegExp(pattern, flags);

      const response = await fetch(`${config.workerUrl}/api/test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ pattern, testString, flags })
      });

      if (!response.ok) {
        throw new Error('Failed to test regex pattern');
      }

      const data = await response.json();
      setResult(data);
    } catch (err) {
      console.error('Regex test error:', err);
      setError(err instanceof Error ? err.message : 'Invalid regular expression');
      setResult(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(testRegex, 500);
    return () => clearTimeout(timeoutId);
  }, [pattern, testString, flags]);

  return {
    pattern,
    flags,
    testString,
    result,
    error,
    isLoading,
    setPattern,
    setFlags,
    setTestString
  };
}
