import React from 'react';
import { Code2 } from 'lucide-react';

interface RegexInputProps {
  pattern: string;
  flags: string;
  error: string | null;
  onPatternChange: (value: string) => void;
  onFlagsChange: (value: string) => void;
}

export function RegexInput({ pattern, flags, error, onPatternChange, onFlagsChange }: RegexInputProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="flex items-center p-3 border-b border-gray-200">
        <Code2 className="w-4 h-4 text-gray-500 mr-2" />
        <input
          type="text"
          value={pattern}
          onChange={(e) => onPatternChange(e.target.value)}
          placeholder="Enter regex pattern"
          className="flex-1 bg-transparent outline-none"
        />
        <input
          type="text"
          value={flags}
          onChange={(e) => onFlagsChange(e.target.value)}
          placeholder="flags"
          className="w-20 text-sm text-gray-600 bg-gray-50 rounded px-2 py-1"
        />
      </div>
      {error && (
        <div className="p-3 text-red-600 text-sm bg-red-50">
          {error}
        </div>
      )}
    </div>
  );
}
