import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { config } from './config';
import App from './App';
import './index.css';

// Debug check on startup
async function checkConnection() {
  if (config.debug) {
    try {
      const response = await fetch(`${config.workerUrl}/api/debug`);
      const data = await response.json();
      console.log('Debug connection check:', data);
    } catch (error) {
      console.error('Connection check failed:', error);
    }
  }
}

checkConnection();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>
);
