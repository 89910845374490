import React from 'react';

interface TestInputProps {
  value: string;
  onChange: (value: string) => void;
}

export function TestInput({ value, onChange }: TestInputProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter test string"
        className="w-full p-3 min-h-[200px] outline-none rounded-lg"
      />
    </div>
  );
}
