import React, { useState } from 'react';
import { Settings, BookOpen, Heart, Share2, HelpCircle } from 'lucide-react';

const menuItems = [
  { icon: Settings, label: 'Settings' },
  { icon: Heart, label: 'Saved Patterns' },
  { icon: BookOpen, label: 'Documentation' },
  { icon: Share2, label: 'Share' },
  { icon: HelpCircle, label: 'Help' }
];

export function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <aside 
      className={`fixed left-0 top-14 h-[calc(100vh-3.5rem)] bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 
        transition-all duration-300 ease-in-out z-40 ${isExpanded ? 'w-64' : 'w-16'}`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <nav className="h-full py-4">
        {menuItems.map((item, index) => {
          const Icon = item.icon;
          return (
            <button
              key={index}
              className={`w-full flex items-center px-4 py-3 text-gray-600 dark:text-gray-300 
                hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors
                ${isExpanded ? 'justify-start' : 'justify-center'}`}
            >
              <Icon className="w-5 h-5 flex-shrink-0" />
              {isExpanded && (
                <span className="ml-3 text-sm whitespace-nowrap">
                  {item.label}
                </span>
              )}
            </button>
          );
        })}
      </nav>
    </aside>
  );
}
