import React from 'react';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { Sidebar } from './components/Sidebar/Sidebar';
import { RegexInput } from './components/RegexInput/RegexInput';
import { TestInput } from './components/TestInput';
import { Results } from './components/Results/Results';
import { Tools } from './components/Tools/Tools';
import { useRegexTester } from './hooks/useRegexTester';
import { ThemeProvider } from './contexts/ThemeContext';

function App() {
  const {
    pattern,
    flags,
    testString,
    result,
    error,
    setPattern,
    setFlags,
    setTestString
  } = useRegexTester();

  return (
    <ThemeProvider>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
        <Header />
        <Sidebar />
        <main className="pt-14 pb-12 pl-16">
          <div className="container mx-auto p-4 space-y-4">
            <RegexInput
              pattern={pattern}
              flags={flags}
              error={error}
              onPatternChange={setPattern}
              onFlagsChange={setFlags}
            />
            <TestInput
              value={testString}
              onChange={setTestString}
            />
            <Tools 
              result={result} 
              testString={testString} 
              pattern={pattern} 
              flags={flags} 
            />
            <Results result={result} />
          </div>
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
