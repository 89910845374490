import React from 'react';

export function Footer() {
  return (
    <footer className="fixed bottom-0 left-0 right-0 h-12 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
      <div className="h-full px-4 flex flex-col items-center justify-center">
        <div className="text-sm text-gray-600 dark:text-gray-300">
          For contact <a href="mailto:proc@awk.fyi" className="text-blue-600 hover:underline dark:text-blue-400">proc@awk.fyi</a>
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-400">
          © {new Date().getFullYear()} All rights reserved.
        </p>
      </div>
    </footer>
  );
}
