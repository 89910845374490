import React from 'react';

interface ResultsProps {
  result: any;
}

export function Results({ result }: ResultsProps) {
  if (!result?.matches?.length) return null;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4">
      <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
        Found {result.matches.length} matches
      </h3>
      
      <div className="space-y-2">
        {result.matches.map((match: any, index: number) => (
          <div 
            key={index}
            className="bg-gray-50 dark:bg-gray-900 rounded-lg p-3 border border-gray-100 dark:border-gray-700"
          >
            <div className="flex items-center justify-between">
              <span className="font-mono text-sm text-blue-600 dark:text-blue-400">
                {match.match}
              </span>
              <span className="text-xs text-gray-500 dark:text-gray-400">
                index: {match.index}
              </span>
            </div>
            {match.groups?.length > 0 && (
              <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                <span className="font-medium">Groups:</span>{' '}
                {match.groups.map((group: string, i: number) => (
                  <span key={i} className="font-mono">
                    {i > 0 && ', '}
                    {group}
                  </span>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
